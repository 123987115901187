<template>
  <div>
    <v-card>
      <v-img class="bg-image" src="@/assets/images/misc/bgd.jpg" height="180"></v-img>

      <v-card-text class="position-relative" style="position: absolute">
        <!-- User Avatar -->
        <v-row>
          <v-col cols="1">
            <!-- <v-avatar class="avatar-center" tile size="100"> -->
            <!-- <vc-image :object-id="parseInt(resid)" table-name="Restaurant" :name-text="resname" /> -->
            <vc-image
              class="avatar-center"
              :width="130"
              :height="130"
              :object-id="parseInt(resid)"
              table-name="Restaurant"
              :label-text="resname"
            />
            <!-- <v-img src="@/assets/images/avatars/1.png"></v-img> -->
            <!-- </v-avatar> -->
          </v-col>
          <v-col cols="1">
            <div class="card-title">
              <p class="text-offwhite pt-0 px-0">{{ resname }}</p>
            </div>
          </v-col >
           <v-col cols="10" class=" align-end">
            <div  class="d-flex flex-row-reverse pt-5" >
               <v-btn v-if="!switching" class="text-offwhite pt-0 px-0 " color="info" @click="switching=!switching">Notes</v-btn>
                <v-btn v-if="switching" class="text-offwhite pt-0 px-0 " color="info" @click="switching=!switching">Exit</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <br />
    <br />
    <br />
      <v-card v-if="switching">
        <restaurant-note
        :RestaurantId="parseInt(resid)"
        ></restaurant-note>
      </v-card>
    <div class="d-flex align-center pb-5">
      <v-expansion-panels focusable v-model="panel" multiple>
        <v-expansion-panel :key="0">
          <v-expansion-panel-header> Orders Information </v-expansion-panel-header>
          <v-expansion-panel-content>
            <customer-order
              :Customer="customerData"
              :CustomerId="customerData.Id"
              :RestaurantId="parseInt(resid)"
              @refetch-data="render()"
            ></customer-order>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel :key="1">
          <v-expansion-panel-header> Customer Information </v-expansion-panel-header>
          <v-expansion-panel-content>
            <!-- @SetBransh="v => (searchQuery = v.BranchId)" -->
            <customer-info
            @SetBransh="v => (searchQuery = v.BranchId)"
            @SetBranshAddress="v => setBranchAdress(v) "
              :RestaurantId="parseInt(resid)"
              :Customer="customerData"
              :HomesArray="HomesArray"
              :isNameSet="isNameSet"
              :rules="[required]"
              :isEmptyAddress="isEmptyAddress"
            ></customer-info>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <span v-if="!reveal">
      <v-card class="mx-auto">
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-spacer></v-spacer>

          <div class="d-flex align-center pb-5">
            <v-text-field
              clearable
              v-model="searchQuery"
              single-line
              dense
              outlined
              hide-details
              placeholder="Search"
              class="Orders-List-search me-3"
            ></v-text-field>

            <!-- status filter -->
            <v-col cols="12" sm="4">
              <v-select
                v-model="statusFilter"
                label="Status"
                :items="$store.state.status['branches']"
                item-text="title"
                item-value="value"
                outlined
                dense
                hide-details
                clearable
              ></v-select>
            </v-col>
          </div>
        </v-card-text>
        <!-- table -->
        <v-data-table
          :headers="tableColumns"
          :items="branchListTable"
          :options.sync="options"
          :server-items-length="totalbranchListTable"
          :loading="loading"
          @click:row="rowdbclick"
        >
          <!-- id -->
          <template #[`item.Id`]="{ item }" style="align: 'start' " > #{{ item.Id }} </template>

          <!-- Name -->
          <template #[`item.Name`]="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
                <span class="d-block text--primary font-weight-semibold text-truncate">{{ item.Name }}</span>
                <span class="text-xs">{{ item.Description }}</span>
              </div>
            </div>
          </template>
          <!-- status -->
          <template #[`item.Status`]="{ item }">
            <v-chip
              small
              :color="
                $store.state.status['branches'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              "
              :class="`${
                $store.state.status['branches'].find(obj => {
                  return obj.value == item.Status
                }).variant || 'primary'
              }--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              {{ item.Status }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </span>
    <span v-if="reveal">
      <order-com
        :OrderBranch="OrderBranch"
        :BranchCategory="BranchCategory"
        :Customer="customerData"
        :BranchCategorytotal="BranchCategorytotal"
        :closereveal="closereveal"
        :isNameSet="isNameSet"
        :key="componentKey"
        :isAddress="isAddress"
      ></order-com>
    </span>
    <v-dialog
      v-model="dialog"
      persistent
      width="250"
    >
      <v-card
      class="mt-5"
      loading="error"
      >
        <v-card-text class="pt-2 pb-2">
          Connection lost, please check your internet connection...
          <v-progress-linear
            indeterminate
            color="error"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
    >
      Please Select Branch !!
      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vue/2.5.8/vue.min.js"></script>
<script>
import VcImage from '@/components/vc-avatar/VcImage.vue'
import { required } from '@core/utils/validation'
import { mdiDeleteOutline, mdiDotsVertical, mdiEyeOutline, mdiPencilOutline, mdiPlus, mdiTrendingUp } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import CustomerInfo from '../Components/CustomerInfo.vue'
import CustomerOrder from '../Components/CustomerOrder.vue'
import OrderCom from '../Components/OrderCom.vue'
import RestaurantNote from '../Components/RestaurantNote.vue'
import useOrderList from './useOrderList'
export default {
  components: {
    RestaurantNote,
    CustomerInfo,
    CustomerOrder,
    VcImage,
    OrderCom,
  },
  setup() {
    const componentKey = ref(0)
    const dialog = ref(false)
    window.onoffline = event => {
      dialog.value = true
      console.log(event)
    }
    window.ononline = event => {
      dialog.value = false
      console.log(event)
    }
    const snackbar = ref(false)
    const {
      isEmptyAddress,
      HomesArray,
      branchListTable,
      customerData,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      fetchBranches,
      fetchCustomersByPhone,
      statusFilter,
      resname,
      resid,
      PhoneNumber,
      rowdbclick,
      reveal,
      OrderBranch,
      BranchCategory,
      BranchCategorytotal,
    } = useOrderList()
    const isNameSet = ref(false)
    const panel = [1]
    const switching = ref(false)
    const closereveal = () => {
      reveal.value = false
    }
    const setBranch = v => {
      searchQuery.value = v.BranchId
    }
    const render = () => {
      console.log('render')
      if (reveal.value == true) {
        componentKey.value = !componentKey.value
        console.log('componentKey.value', componentKey.value)
      } else {
        snackbar.value = true
      }
    }
    const isAddress = ref(false)
    const setBranchAdress = v => {
      console.log('vvvvv', v)
      isAddress.value = true
      componentKey.value = !componentKey.value

      // customerData = v,
    }
    onMounted(() => {
      console.log('kk', isAddress)
      fetchCustomersByPhone()
      if (!navigator.onLine) {
        dialog.value = true
      }
      if (localStorage.getItem('customerData') != undefined) {
        customerData.value = JSON.parse(localStorage.getItem('customerData'))
      }
    })
    return {
      isAddress,
      setBranchAdress,
      isEmptyAddress,
      HomesArray,
      setBranch,
      snackbar,
      componentKey,
      render,
      dialog,
      switching,
      branchListTable,
      customerData,
      tableColumns,
      searchQuery,
      totalbranchListTable,
      loading,
      options,
      branchTotalLocal,
      fetchBranches,
      fetchCustomersByPhone,
      resname,
      statusFilter,
      PhoneNumber,
      panel,
      resid,
      rowdbclick,
      reveal,
      closereveal,
      OrderBranch,
      BranchCategory,
      BranchCategorytotal,
      isNameSet,
      required,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
#Orders-List {
  .Orders-List-actions {
    max-width: 7.81rem;
  }
  .Orders-List-search {
    max-width: 10.625rem;
  }
  .Orders-List-status {
    max-width: 11.3rem;
  }
}
.avatar-center {
  top: -5rem;
  left: 1.3rem;
  border: 2px solid white;
  position: absolute;
}
.card-title {
  top: -3.5rem;
  left: 11rem;
  position: absolute;
  color: #ffffffde;
  font-size: 30px;
  font-family: cursive;
}
.bg-image {
  $img-preload-filter: blur(5px);
}

//@import '@core/preset/preset/pages/auth.scss';
</style>
