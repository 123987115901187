import { GetByBranchId } from '@/@core/api/Categories'
import router from '@/router'
import { GetByRestaurantId } from '@core/api/Branch'
import { GetByPhoneNumber } from '@core/api/Customers'
import { ref, watch } from '@vue/composition-api'

export default function useOrderList() {
  const tableColumns = [
    { text: 'id', value: 'Id' },
    { text: 'name', value: 'Name', width: 200 },
    { text: 'Open', value: 'OpenTime' },
    { text: 'Close', value: 'CloseTime' },
    { text: 'STATUS', value: 'Status', sortable: false },
  ]
  const branchListTable = ref([])
  const customerData = ref({})
  const editedItem = ref({})
  const searchQuery = ref('')
  const resid = ref(router.currentRoute.params.id)
  const branchid = ref(0)
  const statusFilter = ref(null)
  const totalbranchListTable = ref(0)
  const loading = ref(false)
  const resname =ref(router.currentRoute.params.resname)
  const reveal = ref(false)
  const PhoneNumber = ref(router.currentRoute.params.num)
  const OrderBranch = ref({})
  const BranchCategory = ref([])
  const BranchCategorytotal = ref(0)
  const options = ref({
    sortBy: ['Id'],
    sortDesc: [false],
  })

  const branchTotalLocal = ref([])

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const fetchBranches = () => {
    GetByRestaurantId({ id: resid.value, any: searchQuery.value, status: statusFilter.value })
      .then(response => {
        // handle success
        branchListTable.value = response
        totalbranchListTable.value = response.length
        branchTotalLocal.value = response.length
        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const isEmptyAddress = ref(false)
    const HomesArray = ref(['Home', 'Home 2', 'Home 3', 'Work'])
    const findName = (vendors, searchQ) => {
      console.log('vendors', vendors)
      console.log('searchQuery', searchQ)
      var found = false;
    for(var i = 0; i < vendors.length; i++) {
        if (vendors[i].Name == searchQ) {
        //const index = customerData.value.CustomerAddresseses.indexOf(searchQ);
        console.log('index', customerData.value.CustomerAddresseses[i])
        // customerData.value.CustomerAddresseses[i].disabled = "true"
        // customerData.value.CustomerAddresseses[i].disabled = "true"
        HomesArray.value.splice(searchQ, 1);
           break;
        }
    }
    }
    const CA = ref([])

  const fetchCustomersByPhone= () => {
    let phoneNumber = PhoneNumber.value
    GetByPhoneNumber({ PhoneNumber: phoneNumber })
      .then(response => {
        console.log('res customerData.value.CustomerAddresseses res', response.CustomerAddresseses )

        // handle success
        if (response != PhoneNumber.value) {
          customerData.value = response
          let check = customerData.value.CustomerAddresseses
          if(check != undefined && check !=null){
          findName(customerData.value.CustomerAddresseses, 'Home')
          findName(customerData.value.CustomerAddresseses, 'Home 2')
          findName(customerData.value.CustomerAddresseses, 'Home 3')
          findName(customerData.value.CustomerAddresseses, 'Work')
          console.log('HomesArray.value', HomesArray.value)
          localStorage.setItem('customerData', JSON.stringify(customerData.value))
          console.log('if 1', customerData.value.CustomerAddresseses)
          CA.value = customerData.value.CustomerAddresseses
          }
          else {
            isEmptyAddress.value = true
            localStorage.setItem('customerData', JSON.stringify(customerData.value))
            console.log('if 2', customerData.value.CustomerAddresseses )
          }
          // if(response != undefined && response !=null){
          // customerData.value.CustomerAddresseses.forEach((element, index, arr) => {
          //   if (element.Name == 'Home'){
          //     element.disabled = "true"
          //   }
          //   if (element.Name == 'Home 2'){
          //     element.disabled = "true"
          //   }
          //   if (element.Name == 'Home 3'){
          //     element.disabled = "true"

          //   }
          //   if (element.Name == 'Work'){
          //     element.disabled = "true"
          //   }
          //   else {
          //     element.disabled = "false"
          //   }
          // });
          //
          //
          // }
          //
          //
          //
          //
          //
          //


        } else {
          isEmptyAddress.value = true
          customerData.value = { Id: undefined, Name: '', Description: '', Status: '', PhoneNumber: response }
          localStorage.setItem('customerData', JSON.stringify(customerData.value))
          console.log('if 3', customerData.value.CustomerAddresseses )
        }
        loading.value = false
        // handle success
        // if (response != phoneNumber) {
        //   customerData.value = response
        //   localStorage.setItem('customerData', JSON.stringify(customerData.value))
        // } else {
        //   customerData.value = { Id: undefined, Name: '', Description: '', Status: '', PhoneNumber: response }
        //   localStorage.setItem('customerData', JSON.stringify(customerData.value))
        // }
        // loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }
  const rowdbclick = (event, val) => {
    sessionStorage.clear();
    if (val.item.Status == 'online') {
      GetByBranchId({ BranchId: val.item.Id }).then(res => {
        BranchCategory.value = res
        BranchCategorytotal.value = res.length
        OrderBranch.value = val.item
        reveal.value = true
      })
    }
  }
  watch([searchQuery, statusFilter, options], () => {
    loading.value = true
   fetchBranches()
  })
  watch(PhoneNumber, () => {
    loading.value = true
    fetchCustomersByPhone()
  })
  return {
    CA,
    isEmptyAddress,
    HomesArray,
    findName,
    OrderBranch,
    rowdbclick,
    branchListTable,
    tableColumns,
    searchQuery,
    resid,
    statusFilter,
    totalbranchListTable,
    loading,
    options,
    branchTotalLocal,
    fetchBranches,
    fetchCustomersByPhone,
    customerData,
    branchid,
    editedItem,
    resname,
    PhoneNumber,
    reveal,
    BranchCategory,
    BranchCategorytotal,
  }
}
